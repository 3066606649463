import config from './production.js'

const conf = {
    ...config,
    carePortal: {
        urls: {
            adminAuthUrl: 'https://amazing.ulm.demo.m1amdocs.io/',
        },
        agentClientId: 'HaloCTestRP',
        ssoClientId: 'haloeFederationClientID',
        ssoClientSecret: 'secret0.5477682322858153',
        OAuthParam: {
            scope: 'openid profile',
            extras: {},
        },
    },
    urls: {
        ...config.urls,
        apiUrl: 'https://amazing.ulm.demo.m1amdocs.io/platform/rest/v85',
        authUrl: 'https://amazing.ulm.demo.m1amdocs.io',
        userPreferencesUrl: 'https://amazing.ulm.demo.m1amdocs.io/customData',
        storefrontUrl: 'https://amazing.ulm.demo.m1amdocs.io/storefront',
        emi: {
            baseUrl: 'https://waf-attus-emi-wa-sbk001.ifs.vubiquity.com/curation-api',
            homePath: '3.0/{population}/1',
            defaultPopulation: 'stg',
        },
        proxyUrl: 'https://waf-attus-metadata-api-sbk001.ifs.vubiquity.com/maculosa/v3.1',
        ifsApiUrl: 'https://ifs.demo.m1amdocs.io',
        iframePaymentUrl: '',
    },
    clientId: '45ba33b0e7634874ab7f3d7c5d3c45cd',
    encryption: {
        enabled: false,
    },
    env: 'staging',
    OAuthParam: {
        ...config.OAuthParam,
        extras: {
            ...config.OAuthParam.extras,
        },
    },
}

export default conf
